.current-service-desc {
  background: #282B32;
  background-image: url('../../../../imgs/icon/service_stat_bg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.24rem;
  color: #FFFFFF;
  padding: 0.32rem;
  margin: 0.64rem 0.4rem 0.4rem;

  .service-title-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .service-badge {
      display: block;
      height: 0.48rem;
      margin-right: 0.2rem;
    }

    .service-name {
      font-weight: 500;
      font-size: 0.34rem;
      line-height: 0.48rem;
      color: #FFFFFF;
    }
  }

  .member-duration {
    font-weight: 400;
    font-size: 0.22rem;
    line-height: 0.3rem;
    color: #92959E;
    margin-top: 0.1rem;
    margin-bottom: 0.4rem;
  }

  .current-used-plan {
    font-weight: 400;
    font-size: 0.22rem;
    line-height: 0.3rem;
    color: #FFFFFF;

    .orange-text {
      background: linear-gradient(180deg, #FFD08B -23.53%, #FFD08B -3.17%, #D1912E 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      color: #D1912E;
      padding: 0 0.04rem;
    }
  }

}