.chat-top-bar {
  position: sticky;
  top: 0;
  background: #FFFFFF;
  // box-shadow:  0rem 0.02rem 0.08rem rgba(0, 0, 0, 0.16);
  border-bottom: 0.005rem solid #E5E5E5;
  height: 0.88rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0 0.30rem 0 0.36rem;

  &.chat-top-bar--is-not-clickable {
    pointer-events: none;
    opacity: 0.7;
  }

  .top-bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.top-bar-icon--clicked {
      font-weight: bold;
      animation: spin 1s forwards;
    }
  }
  
  .top-bar-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 0.3rem;
    line-height: 0.42rem;
    color: #13151A;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
}