.service-center-page {
  background-image: linear-gradient(to bottom, black 50%, white 50%);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-y: scroll;

  .service-center-top-bar {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    top: 0;
    width: 100%;
    height: 0.88rem;
    padding: 0.32rem 0.4rem;

    .service-center-left-icon {
      position: absolute;
      left: 0.4rem;
      width: 0.4rem;
      display: flex;
      align-items: center;
      height: 100%;
      color: #FFFFFF;
      cursor: pointer;
    }

    .service-center-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0 auto;
      color: #FFFFFF;
      font-weight: 500;
      font-size: 0.3rem;
      line-height: 0.42rem;
    }
  }

  .service-center-button-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-top: 0.01rem solid #E6E7E8;
    padding: 0.24rem 0.32rem 0.92rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .service-center-button-area-save-how {
      margin-right: 0;
      height: 0;
      width: 0; /* 初始宽度为 0 */
      overflow: hidden; /* 隐藏溢出部分 */
      transition: all 0.3s linear; /* 设定过渡效果 */
      vertical-align: middle;
      color: #585858;
      font-weight: 600;
      font-size: 0.26rem;
      line-height: 0.36rem;

      .service-center-save-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .price-number {
        font-weight: 600;
        font-size: 0.34rem;
        line-height: 0.48rem;       
        letter-spacing: -0.01rem;
        color: #000000;
      }

      &.show {
        height: auto;
        width: auto; /* 宽度自动适应文本内容 */
        margin-right: 0.4rem;
        overflow: visible; /* 显示溢出部分 */
        transition: all 0.3s linear; /* 设定过渡效果 */
        vertical-align: middle;
      }
    }

    .service-center-button {
      flex: 1;
      background: linear-gradient(93.22deg, #FFEDAF 18.58%, #FFF4CA 86.97%), linear-gradient(272.66deg, #FDC563 0%, #FECC76 29.33%, #FBEBA4 100%);
      border-radius: 0.4rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      padding: 0.28rem 0.32rem;
      gap: 0.4rem;
      font-style: normal;
      font-weight: 600;
      font-size: 0.3rem;
      line-height: 0.42rem;
      color: #13151A;
      transition: all 0.3s linear;
      vertical-align: middle;
      cursor: pointer;

      &.disabled {
        background: #F8F8F8;
        pointer-events: none;
        cursor: not-allowed;
        font-weight: 600;
        font-size: 0.3rem;
        line-height: 0.42rem;
        display: flex;
        align-items: center;
        color: #92959E;
      }

      .service-center-button-text {
        vertical-align: middle;

        .price-number {

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 800;
          font-size: 0.42rem;
          line-height: 0.52rem;
          margin: 0 0.04rem;

        }
      }
    }
  }
}
