.privacy-agreement-page {
  background-color: #FFFFFF;
  margin: 0;
  padding: 0.24rem;
  line-height: 1.8;
  color: #10273F;


  .chat-content {
    height: 100vh;
    padding-bottom: 1.2rem;
    overflow-y: scroll;
  }
}
