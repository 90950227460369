.chat-input-bar-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  padding-left: 0.32rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-top: 0.01rem solid #E6E7E8;

  &.bottomZero {
    bottom: 0;
  }

  .chat-input {
    flex: 1;
    border: none;
    font-weight: 400;
    font-size: 0.3rem;
    height: auto;
    line-height: 0.42rem;
    max-height: 0.42 * 5rem;
    padding: 0.2rem 0.24rem;
    background: #F8F8F8;
    border-radius: 0.24rem;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
    resize: none;

    &::placeholder {
      color: #92959E;
    }
  
    &:focus {
      outline: none;
      color: #4a4a4a; // 更深的字体颜色
    }
  }

  .input-send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.24rem;
    border: none;
    padding: 0 0.32rem 0 0.16rem;
    transition: all 0.3s ease;
    cursor: pointer;

    .send-button-img {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.4rem;
    }

    .send-button-img-animate {
      animation-name: send-button-animation;
      animation-duration: 1s;
    }

    &.disabled {
      opacity: 0.4;
    }
  }
}

@keyframes send-button-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}