.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* 半透明背景 */
  backdrop-filter: blur(10px); /* 毛玻璃效果 */
  display: flex;
  z-index: 10;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.overlay img {
  max-width: 100%;
  max-height: 100%;
}