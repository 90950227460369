.chat-career-picker-container {
  background-color: #FFF;
  width: 100%;
  position: sticky;
  top: 0.88rem;
  background: #F8F8F8;
  border-bottom: 0.01rem solid #E6E7E8;
  z-index: 2;

  &.notShow {
    display: none;
  }

  .picker-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 0.26rem;
    line-height: 0.36rem;
    color: #92959E;
    padding: 0.16rem 0.32rem 0.1rem;
    
    .picker-bar-icon {
      cursor: pointer;
      margin-left: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms;

      &.isFold {
        transform: rotate(180deg)
      }

      .repick-career {
        display: flex;
        align-items: center;
        justify-content: center;

        .repick-career-text {
          margin-left: 0.1rem;
          color: #13151A;
        }
      }
    }
  }

  .role-picker-list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    transition: all 200ms linear;

    &.isFold {
      display: none;
    }

    .role-picker-list-item {
      flex-shrink: 0;
      font-size: 0.24rem;
      width: 1.1rem;
      height: 1.1rem;
      margin: 0.08rem 0.12rem 0.24rem;
      border: 0.001rem solid lightgray;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      border-radius: 0.24rem;
      text-align: center;
      overflow: hidden;
      cursor: pointer;

      &.picked {
        border-color: #5A64E8;
      }

      &:nth-child(1) {
        margin-left: 0.32rem;
      }

      &:nth-last-child(1) {
        margin-right: 0.32rem;
      }
    }
  }
}