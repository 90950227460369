.nav-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0rem 0.6rem;
  gap: 0.04rem;
  // height: 1.02rem;
  background: #FFFFFF;
  box-shadow:  0rem 0.01rem 0.04rem rgba(0, 0, 0, 0.16);

  flex: none;
  order: 1;
  flex-grow: 0;

  .nav-bar-list {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    .nav-bar-list-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 0.24rem;
      color: #92959E;
      cursor: pointer;

      &.actived {
        color: #13151A;
      }

      .nav-bar-icon {
        display: block;
        height: 0.48rem;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        transition: transform 0.2s ease-out;

        &:active {
          transform: scale(0.5);
        }

        &:before {
          content: "";
          position: absolute;
          top: -10px;
          left: -10px;
          right: -10px;
          bottom: -10px;
          border-radius: 50%;
          opacity: 0;
          background-color: rgba(255, 255, 255, 0.5);
          animation: ripple 0.6s linear;
          animation-fill-mode: forwards;
        }
      }

      .nav-bar-name {
        margin-top: 0.08rem;
      }
    }
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}