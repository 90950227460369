.chat-side-layer {
  position: fixed; 
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.02rem 0.08rem;
  z-index: 30;
  overflow-x: hidden; 
  top: 0;
  bottom: 0;
  left: -6.48rem;
  width: 6rem;
  padding: 0.24rem;
  transition: all 300ms;

  &.active {
    left: 0;
  }

  .chat-side-layer-title {
    font-weight: 500;
    font-size: 0.34rem;
    line-height: 0.48rem;
    color: #13151A;
  }

  .create-new-chat {
    font-weight: 500;
    font-size: 0.3rem;
    height: 0.96rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.24rem;
    padding: 0 0.32rem;
    color:#5A64E8;
    background: rgba(90, 100, 232, 0.15);
    border-radius: 0.24rem;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: 0.8;
    }

    .create-new-chat-btn-text {
      display: block;
      margin-left: 0.1rem;
    }
  }

  .chat-list-loading-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 2rem;
    bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
  .chat-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 2rem;
    bottom: 3rem;
    overflow-y: scroll;
    padding-top: 0.3rem;
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    padding-bottom: 0.6rem;
    overscroll-behavior: contain;

    &.isLogin {
      padding-bottom: 2.6rem;
    }

    .chat-list-item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      font-size: 0.24rem;
      border-radius: 0.24rem;
      padding: 0.24rem;
      position: relative;
      overflow: hidden;
      transition: border-color 0.4rem linear;
      cursor: pointer;

      &.active {
        background: #EEF0F5;

        .topic-and-date-part{
          position: relative;

          .topic-part {
            font-style: normal;
            font-weight: 500;
            font-size: 0.3rem;
            line-height: 0.42rem;
            color: #13151A;
          }
        }
      }

      .topic-and-date-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.10rem;
        position: relative;

        .topic-part {
          flex: 0 0 70%;
          font-weight: 400;
          font-size: 0.30rem;
          line-height: 0.42rem;
          color: #13151A;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.hidden {
            opacity: 0;
            transition: opacity 0.3s ease-out;
          }
        }

        .date-part {
          flex: 0 0 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
          font-size: 0.22rem;
          line-height: 0.38rem;
          color: #92959E;

          &.hidden {
            opacity: 0;
            transition: opacity 0.3s ease-out;
          }
        }

        .confirm-delete-text {
          display: none;
          position: absolute;
          top: 0;
          transition: all 0.3s ease;
          font-weight: 500;
          font-size: 0.26rem;
          line-height: 0.36rem;
          text-align: center;
          color: #13151A;

          &.slide-in {
            display: block;
            width: 100%;
            animation: slide-in 0.3s ease-out forwards;
          }
          
          &.slide-out {
            animation: slide-out 0.3s ease-out forwards;
          }
        }
      }

      .messages-and-delete-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 0.476rem;
        color: #92959E;

        .messages-part {
          flex: 0 0 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-style: normal;
          font-weight: 400;
          font-size: 0.26rem;
          line-height: 0.36rem;
        }

        .delete-part {
          flex: 0 0 20%;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .delete-confirm-part {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .delete-confirm-cancel {
          flex: 1;
          text-align: center;
          background-color: transparent;
          border-radius: 0.16rem;
          color: #000000;
          padding: 0.1rem 0.2rem;
          border: 0.01rem solid #D4D6DB;
          margin-right: 0.08rem;
        }

        .delete-confirm-confirm {
          flex: 1;
          text-align: center;
          background: #5A64E8;
          border-radius: 0.16rem;
          border: 0.01rem solid #5A64E8;
          color: #FFFFFF;
          padding: 0.1rem 0.2rem;
          margin-left: 0.08rem;
        }
      }

      .messages-and-delete-part,
      .delete-confirm-part {
        transform: translateY(0);
        opacity: 1;
        transition: transform 300ms ease-in-out;
        will-change: transform;

        &.slide-up {
          animation: slide-up 0.3s ease-out forwards;
        }
  
        &.slide-down {
          animation: slide-down 0.3s ease-out forwards;
        }
      }
      
      .chat-list-item-title {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .chat-list-user-service {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    // min-height: 2%rem;
    padding: 0 0.2rem 0.52rem;
    background-color: #FFFFFF;

    &.isLoadingData {
      opacity: 0.8;
      pointer-events: none;
    }

    .user-brief-info {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      border-top: 0.02rem solid #E6E7E8;
      padding-top: 0.46rem;
      cursor: pointer;

      .user-avatar {
        flex: 0 0 0.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .default-avatar {
          font-size: 0.72rem;
        }

        .user-img {
          width: 0.8rem;
          height: 0.8rem;
          border: 0.02rem solid #BDBDBD;
          border-radius: 0.4rem;
          display: block;
          overflow: hidden;
        }
      }

      .user-info-block {
        margin-left: 0.2rem;
        font-size: 0.28rem;
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .user-info-name {
          font-weight: 500;
          font-size: 0.34rem;
          line-height: 0.48rem;
          color: #13151A;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .privilege-block {
          display: flex;
          align-items: center;
          justify-content: flex-end;
  
          .privilege-button {
            position: relative;
            width: 1.2rem;
            height: 0.48rem;
            font-weight: 500;
            font-size: 0.22rem;
            line-height: 0.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            color: #000000;

            &.free {
              background-image: url('../../../../imgs/icon/nonmember.svg');
              color: #FFFFFF;
            }

            &.basic {
              background-image: url('../../../../imgs/icon/member.svg');
              color: #FFFFFF;
            }

            &.advance {
              background-image: url('../../../../imgs/icon/supermember.svg');
              color: #965B12;
            }
          }
        }
      }

      .to-service-center {
        flex: 0 0 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: black;
      }
    }

    .service-rest-time {
      margin-top: 0.4rem;

      .service-rest-time-title {
        font-style: normal;
        font-weight: 400;
        font-size: 0.26rem;
        line-height: 0.36rem;
        color: #92959E;
      }
    }

    .login-and-update-button {

      .to-update-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.24rem;
        min-height: 0.96rem;
        font-weight: 500;
        font-size: 0.3rem;
        line-height: 0.42rem;
        color: #13151A;
        background: linear-gradient(93.22deg, #FFEDAF 18.58%, #FFF4CA 86.97%);
        border-radius: 0.24rem;
        cursor: pointer;
      }

      .login-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #13151A;
        border-radius: 0.24rem;
        margin-top: 0.52rem;
        min-height: 0.96rem;
        border: 0.01rem solid #92959E;

        .login-button-main-text {
          font-weight: 500;
          font-size: 0.3rem;
          line-height: 0.42rem;
          color: #FFFFFF;
          text-align: center;
        }

        .login-button-explain-text {
          font-weight: 400;
          font-size: 0.22rem;
          line-height: 0.3rem;
          color: #B2B4BB;
        }
      }
    }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}