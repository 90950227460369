.global-bg {
  position: fixed;
  background: gray;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  filter: alpha(opacity=50);
  opacity: 0.5;
}