.service-intro-block {
  padding: 0;
  .service-intro-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, black 0, #8B6A37 25%, #8B6A37 75%, black 100%);


    .service-intro-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.24rem 0.24rem 0 0;
      padding-top: 0.16rem;
      padding-bottom: 0.16rem;
      color: #E6E7E8;
      border-bottom: 0.01rem solid #FFFFFF;
      background-image: linear-gradient(180deg, #7E581D -23.53%, #8B6A37 -3.17%, #987B51 100%);
      transition: width 0.2s ease-out;
      cursor: pointer;

      .service-badge {
        width: 0.4rem;
        margin-right: 0.16rem;
      }

      .service-intro-tab-text {
        font-style: normal;
        font-weight: 500;
        font-size: 0.3rem;
        line-height: 0.42rem;
        vertical-align: middle;

        .small-font {
          font-size: 0.24rem;
        }
      }

      &:first-child {
        width: 40%;
        border-radius: 0.24rem 0 0 0;
        position: relative;
        z-index: 1;
      }

      &:last-child {
        width: 40%;
        border-radius: 0 0.24rem 0 0;
        position: relative;
        z-index: 2;
      }

      &.active {
        width: 60%;
        background: #FFFFFF;
        position: relative;
        border-bottom: 0.01rem solid #FFFFFF;
        color: #13151A;
        border-radius: 0.24rem 0.24rem 0 0;
      }


    }
  }

  .service-intro-content {
    width: 100%;
    background-color: #FFFFFF;
    
    .service-list {
      padding-top: 0.48rem;
      padding-bottom: 0.48rem;
      padding-left: 0.4rem;
      width: 100%;
      display: block;
      white-space: nowrap;
      overflow-x: auto;

      .service-item {
        display: inline-block;
        overflow: hidden;
        width: 2rem;
        height: 2.4rem;
        border-radius: 0.24rem;
        margin-right: 0.32rem;
        border: 0.02rem solid transparent;
        background-image: url('../../../../imgs/icon/service_set_bg.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;

        &.isPicked {
          background-image: linear-gradient(93.22deg, #FFEDAF 18.58%, #FFF4CA 86.97%);
          border: 0.02rem solid #FDC769;

          .service-item-name {
            background: linear-gradient(180deg, #FFD08B -23.53%, #FFD08B -3.17%, #D1912E 100%);
            color: #965B12;
            mix-blend-mode: normal;
          }

          .service-item-duration {
            color: #896623;
          }
        }

        .service-item-name {
          font-weight: 500;
          font-size: 0.26rem;
          line-height: 0.36rem;
          padding: 0.08rem 0.24rem;
          display: inline-block;
          color: #13151A;
          background: linear-gradient(180deg, rgba(255, 208, 139, 0.6) -23.53%, rgba(255, 208, 139, 0.6) -3.17%, rgba(209, 145, 46, 0.6) 100%);
          mix-blend-mode: luminosity;
          border-radius: 0.24rem 0px;
        }

        .service-item-duration {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #13151A;
          font-weight: 500;
          font-size: 0.34rem;
          line-height: 0.48rem;
          padding: 0.08rem 0;
        }
        
        .service-item-price {
          font-family: 'Montserrat';
          display: flex;
          align-items: center;
          justify-content: center;
          // font-style: italic;
          font-weight: 800;
          font-size: 0.54rem;
          line-height: 0.66rem;
          color: #965B12;
        }

        .service-item-already-saved {
          font-weight: 400;
          font-size: 0.22rem;
          line-height: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration-line: line-through;
          color: #797258;
        }
      }
    }
  }
}
