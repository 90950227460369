

.loading-dots span {
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #555;
  margin-right: 5px;
  opacity: 0;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading-dots {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.loading-dots span:nth-child(1) {
  animation-name: loading-dots;
}

.loading-dots span:nth-child(2) {
  animation-name: loading-dots;
}

.loading-dots span:nth-child(3) {
  animation-name: loading-dots;
}
