.mine-setting-block {
  padding: 0 0.4rem;

  .mine-setting-block-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1rem;

    &.general-setting {
      cursor: pointer;
    }

    .left-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #13151A;
    }

    .semantic-version {
      font-weight: 500;
      font-size: 0.26rem;
      line-height: 0.34rem;
      color: #92959E;
      margin-right: 0.1rem;
    }
  }
}