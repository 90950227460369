@font-face {
  font-family: "iconfont"; /* Project id 4010419 */
  src: url('iconfont.woff2?t=1681263585626') format('woff2'),
       url('iconfont.woff?t=1681263585626') format('woff'),
       url('iconfont.ttf?t=1681263585626') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user_avatar:before {
  content: "\e8dc";
}