.chat-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  // background-color: lightblue;
  // z-index: 3;
  overflow-y: auto;
  // -webkit-overflow-scrolling: touch;

  .children-container {
    flex: 1;
  }
}