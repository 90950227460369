.benefits-list {
  width: 100%;
  background-color: #FFFFFF;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0.8rem;

  .benefits-list-title {
    height: 0.48rem;
    width: 100%;
    background-image: url('../../../../imgs/icon/service_pv_title.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 0.24rem;
    margin-bottom: 0.24rem;
  }

  .benefits-block-list {
    padding: 0.32rem;
    background: #F8F8F8;
    border-radius: 0.24rem;

    .benefits-block-item {
      color: #13151A;
      font-weight: 400;
      font-size: 0.3rem;
      line-height: 0.42rem;
      margin-bottom: 0.24rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .service-badge {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.12rem;
      }

      .detail-item-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}