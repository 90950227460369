.chat-main-area {
  // background-color: lightgreen;
  position: absolute;
  top: 1.34rem;
  bottom: 2.08rem;
  left: 0;
  right: 0;
  overflow-y: scroll;

  .chat-main-area-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.isCareerPickerHide {
    top: 0.94rem;
  }

  .chat-main-list {
    padding: 1.2rem 0.2rem;

    &.isCareerPickerFoldOrHide {
      padding-top: 0;
    }

    .chat-main-list-item-for-load-more {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: lightcoral;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }

    .bottom-of-message-list {
      width: 100%;
      height: 0.2rem;
    }

    .chat-main-list-item {
      display: block;
      margin-top: 0.4rem;
      display: flex;
      flex-direction: column;

      &.isUser {
        align-items: flex-end;
      }

      &.isAI {
        align-items: flex-start;
      }

      &.isSystem {
        align-items: flex-start;
      }

      .chat-date {
        margin: 0 0.24rem;
      }

      .chat-assistant-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.1rem;

        .chatgpt-avatar {
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 0.24rem;
          background-color: #FFFFFF;
          display: block;
          overflow: hidden;
        }

        .copy-content-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          
          .copy-content-icon {
            display: block;
          }
        }
      }

      .chat-avatar-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.1rem;

        &.isUser {
          justify-content: flex-end;
        }
  
        &.isAI {
          justify-content: flex-start;
        }
  
        &.isSystem {
          justify-content: flex-start;
        }

        .default-avatar {
          font-size: 0.48rem;
          margin-left: 0.09rem;
        }

        .user-img {
          width: 0.48rem;
          height: 0.48rem;
          border: 0.02rem solid #BDBDBD;
          border-radius: 0.24rem;
          background-color: #FFFFFF;
          display: block;
          overflow: hidden;
        }
      }

      .chat-content {
        box-sizing: border-box;
        max-width: 80%;
        margin-top: 0.2rem;
        border-radius: 0.2rem;
        padding: 0.2rem;
        font-size: 0.3rem;
        line-height: 0.42rem;
        user-select: text;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        word-wrap: break-word;
        position: relative;

        .chat-assistant-buttons {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 400;
          font-size: 0.26rem;
          line-height: 0.36rem;

          .chat-assistant-left-button {
            background: #5A64E8;
            border: 0.01rem solid #5A64E8;
            border-radius: 0.4rem;
            padding: 0.1rem 0.32rem;
            color: #FFFFFF;
          }

          .chat-assistant-right-button {
            font-size: 0.26rem;
            line-height: 0.36rem;
            padding: 0.1rem 0.32rem;
            border-radius: 0.4rem;
            color: #13151A;
            border: 0.01rem solid lightgray;
            margin-left: 0.2rem;
          }
        }

        &.isUser {
          background-color: #13151A;
          color: #fff;
          border-top-right-radius: 0;
        }
  
        &.isAI {
          background-color: #F8F8F8;
          color: #13151A;
          border-top-left-radius: 0;
        }
  
        &.isSystem {
          background-color: #F8F8F8;
          color: #13151A;
          border-top-left-radius: 0;
        }
      }
    }

    // 次数耗尽提示
    .free-times-used-up {
      margin-top: 0.56rem;
      margin-bottom: 0.56rem;

      .free-times-used-up-title-text {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.32rem;
        font-style: normal;
        font-weight: 400;
        font-size: 0.26rem;
        line-height: 0.36rem;
        color: #92959E;

        &::before, &::after {
          content: "";
          position: absolute;
          bottom: 50%;
          width: 0.8rem;
          border-bottom: 0.02rem solid #E6E7E8;
        }

        &::before {
          left: 0.28rem;
        }

        &::after {
          right: 0.28rem;
        }

      }

      .free-time-usd-up-buttons {
        margin-top: 0.24rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .to-update-btn {
          padding: 0.18rem 0.32rem;
          gap: 0.2rem;
          background: #5A64E8;
          font-weight: 400;
          font-size: 0.26rem;
          line-height: 0.36rem;
          color: #FFFFFF;
          border-radius: 0.4rem;
        }

        .to-recharge-btn {
          padding: 0.18rem 0.32rem;
          gap: 0.2rem;
          background: #FFFFFF;
          font-weight: 400;
          font-size: 0.26rem;
          line-height: 0.36rem;
          color: #13151A;
          border-radius: 0.4rem;
          border: 0.01rem solid lightgray;
          margin-left: 0.1rem;
        }
      }

    }
  }
}