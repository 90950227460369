.mine-membership-card-container {
  padding: 0 0.32rem 0.24rem;
  z-index: 3;

  .mine-membership-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 2.6rem;
    overflow: hidden;
    border-radius: 0.24rem;
    // background-color: #000000;
    background-image: url('../../../../imgs/icon/mine_user_bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .top-part {
      flex: 1;
      padding: 0 0.24rem;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      overflow: hidden;

      .user-avatar-block {
        width: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .default-avatar {
          font-size: 0.72rem;
        }

        .user-img {
          width: 0.52rem;
          height: 0.52rem;
          border: 0.02rem solid #BDBDBD;
          border-radius: 0.26rem;
          display: block;
          overflow: hidden;
          background-color: #FFFFFF;

        }
      }

      .user-name-member-time {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .user-name {
          font-weight: 500;
          font-size: 0.3rem;
          line-height: 0.42rem;         
          display: flex;
          align-items: center;
          color: #FFFFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .member-time {
          font-weight: 400;
          font-size: 0.22rem;
          line-height: 0.3rem;
          color: #92959E;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .bottom-part {
      flex: 1;
      padding: 0 0.24rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;


      .service-title {
        font-weight: 700;
        font-size: 0.34rem;
        line-height: 0.42rem;
        display: flex;
        align-items: center;
        color: #000000;
        padding-left: 0.1rem;
      }

      .service-badge-and-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .service-badge {
          display: block;
          width: 0.44rem;
          position: relative;
          animation: floating 3s ease-in-out infinite;
        }

        .service-title {
          font-weight: 500;
          font-size: 0.26rem;
          line-height: 0.36rem;
          color: #FFFFFF;
        }
      }

      .prolong-btn {
        font-weight: 500;
        font-size: 0.26rem;
        line-height: 0.36rem;
        text-align: center;
        color: #000000;
        background: #FFFFFF;
        padding: 0.18rem 0.4rem;
        border-radius: 0.24rem;
        cursor: pointer;

        &.silver {
          background: linear-gradient(93.22deg, #E7E7E7 18.58%, #FFFFFF 86.97%);
        }

        &.gold {
          background: linear-gradient(93.22deg, #FFEDAF 18.58%, #FFF4CA 86.97%);
        }
      }
    }
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.08rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shadow {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}