div.redirect-to-chat-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

h1.redirect-to-chat-slogan {
  font-family: Arial, sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #333;
  text-align: center;
  text-shadow: 2px 2px 5px #ccc;
}