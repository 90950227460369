.mine-invite-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.32rem 0.16rem;
  text-align: center;
  padding: 0.24rem;
  background: linear-gradient(93.43deg, #5A64E8 0%, #AC6AFF 100%), linear-gradient(91.3deg, #F741AB 0.44%, #FFC569 99.34%);
  border-radius: 0.24rem;
  cursor: pointer;

  .invite-title-and-invitee-list {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 0.24rem;

    .invite-title {
      flex: 1;
      text-align: left;
      font-size: 0.26rem;
      line-height: 0.36rem;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
    }

    .already-gained-days {
      flex: 1;
      font-size: 0.26rem;
      line-height: 0.36rem;
      font-weight: 500;
      text-align: right;
      color: #FFFFFF;
    }
  }

  .invite-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 0.16rem;
    width: 100%;
    height: 0.72rem;
    font-weight: 500;
    font-size: 0.26rem;
    line-height: 0.36rem;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0.16rem;
  }

  .invitee-list {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  
    .invitee-list-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F8F8F8;
      border: 0.02rem solid #FFFFFF;
      width: 0.72rem;
      height: 0.72rem;
      overflow: hidden;
      border-radius: 50%;

      .invitee-list-item-avatar {
        display: block;
        object-fit: contain;
        width: 0.72rem;
        height: 0.72rem;
      }

      &.plus-btn {
        border: 0.02rem solid transparent;
        background: transparent;
      }

      &:nth-of-type(2) {
        left: -0.1rem;
      }
      &:nth-of-type(3) {
        left: -0.2rem;
      }
      &:nth-of-type(4) {
        left: -0.3rem;
      }
      &:nth-of-type(5) {
        left: -0.4rem;
      }
      &:nth-of-type(6) {
        left: -0.5rem;
      }
      &:nth-of-type(7) {
        left: -0.6rem;
      }
      &:nth-of-type(8) {
        left: -0.7rem;
      }
      &:nth-of-type(9) {
        left: -0.8rem;
      }
      &:nth-of-type(10) {
        left: -0.9rem;
      }
      &:nth-of-type(11) {
        left: -1rem;
      }
  
    }
  }
}