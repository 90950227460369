.mine-page {
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  overflow-y: scroll;
  background-color: #FFFFFF;

  .mine-page-title {
    font-weight: 600;
    font-size: 0.42rem;
    line-height: 0.58rem;
    color: #000000;
    padding: 0 0.4rem;
  }
}
